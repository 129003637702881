import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, Text, Box, Link, Section, Strong } from "@quarkly/widgets";
import { BsArrowRight } from "react-icons/bs";
const defaultProps = {
	"padding": "80px 0 30px 0",
	"background": "--color-darkL2",
	"quarkly-title": "Footer-14"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0px 80px 0px",
			"sm-flex-direction": "column",
			"sm-margin": "0px 0px 60px 0px",
			"md-margin": "0px 0px 45px 0px",
			"display": "none"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsArrowRight,
			"size": "54px",
			"color": "--light",
			"margin": "0px 50px 0px 0px",
			"md-margin": "0px 35px 0px 0px",
			"sm-margin": "0px 0 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 450px 0px 0px",
			"border-color": "--color-light",
			"color": "--light",
			"font": "--headline3",
			"lg-margin": "0px 0 0px 0px",
			"md-font": "normal 400 72px/1.2 --fontFamily-sans",
			"sm-font": "normal 400 52px/1.2 --fontFamily-sans",
			"children": "Join the Adventure!"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 80px 0px",
			"md-margin": "0px 0px 45px 0px",
			"justify-content": "space-between",
			"align-items": "flex-start",
			"md-flex-direction": "column",
			"md-display": "flex",
			"md-grid-gap": "32px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"md-margin": "0px 0px 0 0px",
			"md-width": "30%",
			"display": "inline-block",
			"text-decoration-line": "initial",
			"href": "/",
			"font": "--lead",
			"children": <>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Railways Adventure:{" "}
					<br />
				</Strong>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Master the Art of Train Management
				</Strong>
			</>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "36px 54px",
			"lg-align-items": "start",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"md-grid-gap": "36px 34px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "36px 24px",
			"md-width": "100%",
			"sm-width": "100%",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"width": "100%",
			"justify-content": "space-around"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"flex-wrap": "wrap",
			"align-content": "start",
			"grid-gap": "8px 0",
			"lg-justify-content": "start"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "#c3c8d0",
			"letter-spacing": "1px",
			"children": "CONTACTS"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "#",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"children": <>
				Shop 1/369-371 Mont Albert Rd,{" "}
				<br />
				Mont Albert VIC 3127, Australia
			</>
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "tel:+61732523900",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"children": "+61398903012"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "mailto:info@Viroarcadia.com",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"children": "info@viroarcadia.com"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"flex-wrap": "wrap",
			"align-content": "start",
			"grid-gap": "8px 0",
			"lg-justify-content": "start"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "#c3c8d0",
			"letter-spacing": "1px",
			"children": "NAVIGATION"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "#about",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"children": "About"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "#gallery",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"children": "Gallery"
		}
	},
	"link6": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "#features",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"children": "Features"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"justify-content": "space-between"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"font": "--base",
			"color": "#c3c8d0",
			"md-margin": "0px 0px 0 0px",
			"width": "30%",
			"md-width": "100%",
			"margin": "0 0px 0 0px",
			"children": <>
				© 2024 Viroarcadia, Inc.{" "}
			</>
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 0px 0px -",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"md-order": "-1",
			"md-margin": "0px 0px 25px 0px",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "0 24px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "10px 0",
			"md-display": "flex"
		}
	},
	"link7": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-margin": "0px 6px 0px 0px",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/privacy-policy",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"children": "Privacy Policy"
		}
	},
	"link8": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"lg-margin": "0px 6px 0px 0px",
			"display": "flex",
			"href": "/terms-and-conditions",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"children": "Terms of Service"
		}
	}
};

const Footer2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Icon {...override("icon")} />
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<Link {...override("link")} />
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<Text {...override("text1")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
					<Link {...override("link3")} />
				</Box>
				<Box {...override("box4")}>
					<Text {...override("text2")} />
					<Link {...override("link4")} />
					<Link {...override("link5")} />
					<Link {...override("link6")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box5")}>
			<Text {...override("text3")} />
			<Box {...override("box6")}>
				<Link {...override("link7")} />
				<Link {...override("link8")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer2, { ...Section,
	defaultProps,
	overrides
});
export default Footer2;